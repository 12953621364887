import React from 'react';

import Layout from '@src/layouts';

import {
  SectionUnderHeaderWithUnderline,
  DesktopHeaderLayoutB,
  MobileHeaderLayoutA,
  MeetCodilimeSection,
  NewClientSingleTestimonial,
  SliderWithClickableBlocks,
  ColoredBlocksWithRedirects,
  FlipCardsSection,
  BlocksInRowsWithOneActive,
  OpacityScrolledSlider,
  BlocksRedirectingToResources,
  TechStack,
  SectionWithParagraphs,
  ContactButtonImageSection,
} from '@commons/index';

import SEO from '@commons/SEO';

import {
  mvpDevelopmentSEOProps,
  headerProps,
  sectionUnderHeaderProps,
  blocksInRowsWithOneActiveProps,
  flipCardsSectionProps,
  sliderWithClickableBlocksProps,
  testimonialProps,
  opacityScrolledSliderProps,
  blocksRedirectingToResourcesProps,
  coloredBlocksWithRedirectsProps,
  techStackProps,
  mvpDevelopmentProcessProps,
  contactButtonImageSectionProps,
} from '@pages-impl/mvp-development/payload';

export default function MVPDevelopment(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Start your journey to MVP now"
      {...props}
    >
      <SEO {...mvpDevelopmentSEOProps} />
      <MobileHeaderLayoutA {...headerProps} />
      <DesktopHeaderLayoutB {...headerProps} />
      <SectionUnderHeaderWithUnderline {...sectionUnderHeaderProps} />
      <BlocksInRowsWithOneActive {...blocksInRowsWithOneActiveProps} />
      <FlipCardsSection {...flipCardsSectionProps} />
      <OpacityScrolledSlider {...opacityScrolledSliderProps} />
      <SectionWithParagraphs
        sectionProps={mvpDevelopmentProcessProps.sectionProps}
        paragraphsData={mvpDevelopmentProcessProps.developmentProcessData}
      />
      <SliderWithClickableBlocks {...sliderWithClickableBlocksProps} />
      <TechStack {...techStackProps} />
      <ContactButtonImageSection {...contactButtonImageSectionProps} />
      <NewClientSingleTestimonial {...testimonialProps} />
      <BlocksRedirectingToResources {...blocksRedirectingToResourcesProps} />
      <MeetCodilimeSection />
      <ColoredBlocksWithRedirects {...coloredBlocksWithRedirectsProps} />
    </Layout>
  );
}
