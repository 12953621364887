// extracted by mini-css-extract-plugin
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--27af2";
export var blocksInRowsWithOneActiveSection = "payload-module--blocks-in-rows-with-one-active-section--8a691";
export var blocksRedirectingToResourcesInnerWrapper = "payload-module--blocks-redirecting-to-resources-inner-wrapper--75690";
export var cardContainer = "payload-module--card-container--7f03d";
export var cardContainerTechStack = "payload-module--card-container-tech-stack--b4ce8";
export var cardWithoutLink = "payload-module--card-without-link--a27ed";
export var coloredBlocksContainer = "payload-module--colored-blocks-container--6eaea";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--b4a77";
export var contactButtonImageSection = "payload-module--contact-button-image-section--0e71c";
export var contactButtonImageSectionInnerWrapper = "payload-module--contact-button-image-section-inner-wrapper--456f9";
export var customSubtitle = "payload-module--custom-subtitle--f176d";
export var desktopContainer = "payload-module--desktop-container--c302f";
export var flipCardsSectionDescription = "payload-module--flip-cards-section-description--e9a22";
export var innerWrapper = "payload-module--inner-wrapper--b88c6";
export var innerWrapperTechStack = "payload-module--inner-wrapper-tech-stack--95051";
export var innerWrapperTestimonials = "payload-module--inner-wrapper-testimonials--0d1a3";
export var newLineWithMargin = "payload-module--new-line-with-margin--da3c5";
export var secondPartOfText = "payload-module--second-part-of-text--fc6dd";
export var sectionWithParagraphsTitlesContainer = "payload-module--section-with-paragraphs-titles-container--b6bc3";
export var singleBlockContainer = "payload-module--single-block-container--a0101";
export var sliderWithClickableBlocksSection = "payload-module--slider-with-clickable-blocks-section--cf435";
export var techStackContainer = "payload-module--tech-stack-container--204d5";
export var titlesContainer = "payload-module--titles-container--4966c";
export var titlesContainerTechStack = "payload-module--titles-container-tech-stack--7b7bd";
export var underHeaderInnerWrapper = "payload-module--under-header-inner-wrapper--35a7d";