import React from 'react';

import featuredImage from './images/featured-image-mvp-development.jpg';

import FluxNinja from '@images/logos-partners/FluxNinja-logo.svg';

import {
  python,
  react,
  go,
  java,
  angular,
  django,
  spring,
  javaScript,
  typeScript,
  googleCloud,
  aws,
  azure,
} from '@images/logos-techstack-bigger';

import { paths } from '@src/constants/pathsEnum';

import * as styles from './payload.module.scss';

export const mvpDevelopmentSEOProps = {
  title: 'MVP Development Services Company | CodiLime Strategic Partner',
  description:
    'Seeking a partner with experience in building MVPs for network solutions? Look no further. At CodiLime, we’ve been doing this since 2011!',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: (
    <>
      Minimum
      <br /> Viable Product
      <br /> development
      <br /> services
    </>
  ),
  isBorder: false,
  classNames: {
    header: styles.headerStyles,
    innerWrapper: styles.headerInnerWrapper,
    titleStyle: styles.headerTitle,
  },
};

export const sectionUnderHeaderProps = {
  textPartOne:
    'Are you in two minds whether your idea has real business potential and can win the market or not? Check its feasibility with a well-designed and developed MVP.',
  contactButtonLabel: 'Start MVP now!',
  classNames: {
    innerWrapper: styles.underHeaderInnerWrapper,
  },
};

export const blocksInRowsWithOneActiveProps = {
  title: 'MVP development services to boost your product',
  subtitle: (
    <>
      Implementing an MVP in a complex process requires professional support, especially when it comes to network
      solutions. <br className={styles.newLineWithMargin} />
      Explore the MVP services we perform to make your products and services outstanding.
    </>
  ),
  blocks: [
    {
      title: 'Planning an MVP software development',
      description: (
        <>
          We help you with defining the solution that an MVP should deliver. You can rely on us for{' '}
          <b>functional analysis of the product</b>, domain requirements, and minimum functionalities prioritization.
          Moreover, we provide you with tailor-made high-level design, including technology stack, architecture
          proposition, and time and budget estimates.
        </>
      ),
    },
    {
      title: 'UX discovery phase',
      description: (
        <>
          During the UX discovery phase, you can expect a <b>kick-off meeting with the UX team</b>, whiteboarding, first
          Figma visualization, and lo-fi frames. The final stage of this phase will be the design of the product&apos;s
          visual side.
        </>
      ),
    },
    {
      title: 'MVP implementation',
      description: (
        <>
          While implementing the MVP, we provide you with a choice of technology stack and architecture for the first
          iteration. Our developers will code the product&apos;s frontend and backend and provide a{' '}
          <b>CI/CD for further deployments</b>. Our teams work in line with the Agile approach, so we benefit from Agile
          methodology methods while delivering closed scopes as part of the MVP.
        </>
      ),
    },
  ],
  classNames: {
    section: styles.blocksInRowsWithOneActiveSection,
    innerWrapper: styles.blocksInRowsWithOneActiveInnerWrapper,
    subtitleStyles: styles.customSubtitle,
    singleBlockContainer: styles.singleBlockContainer,
    titlesContainer: styles.titlesContainer,
  },
};

export const flipCardsSectionProps = {
  sectionProps: {
    title: 'How about our expertise?',
    id: 'mvp-expertise',
    subtitle: (
      <>
        At CodiLime, we support companies from various fields. We are an experienced MVP development company that has
        been providing services for{' '}
        <b>networking and infrastructure companies, the fintech industry, AI/ML and cloud platforms, and startups</b>.
        Below you can check how we&apos;ve helped in detail:
      </>
    ),
    classNames: {
      subtitleStyles: styles.flipCardsSectionDescription,
    },
  },
  customCardsContainerStyles: styles.cardContainer,
  isFlippable: true,
  flipCardsData: [
    {
      title: 'Product discovery',
      description: (
        <>
          We offer scope definition of the MVP. Moreover, our UX team will prepare a <b>UX roadmap</b> and designs in
          Figma. We will also deliver technology recommendations and architecture.
        </>
      ),
      color: '#041C2E',
      paddingTop: '110px',
    },
    {
      title: 'MVP software development',
      description: (
        <>
          Thanks to working in line with the <b>Agile approach</b>, our teams and your product benefit from Agile
          methods while delivering closed scopes as part of the MVP.
        </>
      ),
      color: '#053356',
      paddingTop: '86px',
    },
    {
      title: 'MVP implementation and testing',
      description: (
        <>
          We perform unit and automation regression tests and <b>CI/CD implementation</b>. Moreover, we prepare product
          demonstration and acceptance tests at the end of every sprint.
        </>
      ),
      color: '#122B3F',
      paddingTop: '88px',
    },
    {
      title: 'Changing the MVP into a finished product',
      description: (
        <>
          Our teams will support you while developing the current MVP. We&apos;re here to help you{' '}
          <b>analyze features and user behavior</b> and implement the necessary changes and improvements.
        </>
      ),
      color: '#021A2D',
      paddingTop: '86px',
    },
  ],
};

export const sliderWithClickableBlocksProps = {
  sectionProps: {
    title: 'Our models for outsourced development services',
    subtitle: (
      <>
        At CodiLime, we want to offer our worldwide partners maximum freedom and flexibility. That&apos;s why we have{' '}
        <b>several cooperation models to meet your needs as best as possible</b>. You can choose from team augmentation,
        a tailored team, end-to-end services, or consulting & expertise - whatever is in line with your current
        requirements.
      </>
    ),
  },
  classNames: {
    section: styles.sliderWithClickableBlocksSection,
    innerWrapper: styles.innerWrapper,
    titlesContainer: styles.titlesContainer,
  },
};

export const testimonialProps = {
  testimonial: {
    quote: `In the startup world, founders’ primary focus is on gathering requirements from users and researching the latest technologies for architecture, etc. However, you need a trusted and skilled engineering team to get all these things implemented. FluxNinja team has partnered with CodiLime to hire that engineering talent. We have been super impressed with the quality of the work and dedication of the CodiLime team. It has been a very cost-effective process and helped us scale our team to over 15 team members in 3 months. Thank you, team CodiLime!`,
    author: 'Jai Desai - Co-Founder & COO - FluxNinja, Inc.',
    logo: FluxNinja,
    logoAlt: 'FluxNinja',
  },
  leftTitle: 'Why develop an MVP with CodiLime?',
  classNames: {
    innerWrapper: styles.innerWrapperTestimonials,
  },
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: 'More about MVPs from CodiLime',
    subtitle: (
      <>
        Do you want to know more about minimum viable product development? On our blog you can find more{' '}
        <b>MVP-related publications</b> that give you a better insight into the topic.
      </>
    ),
    id: 'our-articles',
    classNames: {
      innerWrapper: styles.blocksRedirectingToResourcesInnerWrapper,
      titlesContainer: styles.titlesContainer,
    },
  },
  blocks: [
    {
      text: 'MVP Strategy & Implementation for Network Applications',
      link: '/blog/mvp-strategy-and-implementation-for-network-applications/',
    },
    {
      text: 'Creating an MVP for a Network Application',
      link: '/blog/creating-an-mvp-for-a-network-application/',
    },
    {
      text: 'MVP roadmap—your guide to a successful product',
      link: '/blog/mvp-roadmap/',
    },
    {
      text: 'How to build a successful Minimum Viable Product',
      link: '/blog/how-to-build-a-successful-minimum-viable-product/',
    },
    {
      text: 'Building an MVP: 6 common mistakes to avoid',
      link: '/blog/building-mvp-common-mistakes-to-avoid/',
    },
    {
      text: 'MVP vs. prototype',
      link: '/blog/mvp-vs-prototype/',
    },
  ],
};

export const opacityScrolledSliderProps = {
  title: 'Why build a Minimum Viable Product?',
  id: 'opacity-slider',
  shiftDown: 0.3,
  topOpacity: 0.44,
  textBlocks: [
    <>
      <span>Early idea validation</span> to limit unnecessary spending and employee effort
    </>,
    <>
      In case of failed idea feasibility test, <span>the budget can be saved for another project</span>
    </>,
    <>
      Focus on top-priority features <span>shorten the product&apos;s time-to-market</span>
    </>,
    <>
      <span>Seamless improvement</span> of MVP allows faster adoption of users&apos; requirements and needs
    </>,
  ],
};

export const coloredBlocksWithRedirectsProps = {
  title: 'Other services',
  subtitle: (
    <>
      While developing an MVP you can also think about the subsequent stages of product development services.{' '}
      <b>Great UX&UI</b> for your solution? Team to shorten time-to-market? No worries! We&apos;re here for you! Check
      out how we use our CodiLime superpowers to make out of this world products for our clients.
    </>
  ),
  blocks: [
    {
      text: (
        <>
          <span>UX&UI</span>
        </>
      ),
      link: paths.SERVICES.UX_UI_DESIGN,
    },
    {
      text: (
        <>
          <span>Software outcourcing </span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.SOFTWARE_OUTSOURCING,
    },
    {
      text: (
        <>
          <span>Custom software </span>
          <span>development services</span>
        </>
      ),
      link: paths.SERVICES.CUSTOM_DEVELOPMENT,
    },
    {
      text: (
        <>
          <span>Low-level programming</span>
        </>
      ),
      link: paths.SERVICES.LOW_LEVEL,
    },
  ],
  classNames: {
    innerWrapper: styles.coloredBlocksInnerWrapper,
    container: styles.coloredBlocksContainer,
  },
};

export const techStackProps = {
  sectionProps: {
    title: 'Technologies we use to build MVPs',
    subtitle: <>While developing MVPs our teams use a selection of technologies we trust. Check out our MVP toolkit!</>,
    classNames: {
      titlesContainer: styles.titlesContainerTechStack,
      innerWrapper: styles.innerWrapperTechStack,
    },
  },
  data: [python, react, go, java, angular, django, spring, javaScript, typeScript, googleCloud, aws, azure],
  lessInFirstLine: false,
  classNames: {
    techStackContainer: styles.techStackContainer,
    desktopContainer: styles.desktopContainer,
    cardContainer: styles.cardContainerTechStack,
    cardWithoutLink: styles.cardWithoutLink,
  },
};

export const mvpDevelopmentProcessProps = {
  sectionProps: {
    id: 'our-process',
    title: 'The MVP development process at CodiLime',
    classNames: {
      titlesContainer: styles.sectionWithParagraphsTitlesContainer,
    },
  },
  developmentProcessData: [
    {
      title: 'Discovery',
      description:
        'During this phase, we will define the scope of the MVP together. Based on that you can expect a UX roadmap and Figma designs for the first sprint of the implementation phase. As a leading MVP development company, we will also propose a technology stack and architecture in the form of high-level design (HLD).',
    },
    {
      title: 'Implementation',
      description:
        'Here we’ll implement the scope described within the MVP and perform unit and automation regression tests. Besides testing, we’ll provide CI/CD implementation, and prepare cloud environments for software development, testing and production. We’ll make sure that the whole process is well documented. Moreover, we guarantee product demonstration and acceptance tests at the end of every sprint.',
    },
    {
      title: 'First release',
      description:
        "Now it's time for acceptance testing of the whole MVP scope, and production deployment as a result of the final confirmation. After these steps, we’ll give you comprehensive MVP documentation.",
    },
    {
      title: 'Stabilization',
      description:
        'During the final stage, the product will be used by your most trusted users - “family and friends testing”. Simultaneously, while applying necessary hotfixes and improving the user experience by applying minor changes, we will be able to work on the scope, post-MVP.',
    },
  ],
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      section: styles.contactButtonImageSection,
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
    },
  },
  title: (
    <>
      Plan your <span className={styles.secondPartOfText}>MVP with us</span>
    </>
  ),
  imageAlt: 'Plan your Minimum Viable Product',
  isH2: true,
};
